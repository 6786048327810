import React from "react"
import Layout from "../../../components/Layout"
import Hero from "../../../components/Hero"
import Breadcrumb from "../../../components/Breadcrumb"
import Searcher from "../../../components/Searcher"
import { menuOptions } from "../../../data/layout-test-data"
import CollapseMenu from "../../../components/Collapse/CollapseMenu"
import Hours from "../../../components/Hours"
import { activities1 } from "../../../data/news-tab"
import NewsTabContent from "../../../components/NewsTab/NewsTabContent"
import NewsTabHead from "../../../components/NewsTab/NewsTabHead"
import { activityContent1 } from "../../../data/activityTab"
import parse from "react-html-parser"
import NewsTabSocial from "../../../components/NewsTab/NewsTabSocial"

export default function ActivityTab() {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Servicios y actividades", to: "/servicios-y-actividades" },
    { label: "Exámenes", to: "/examenes-web" },
    { label: "2a Convocatoria 2021 examen EFP (European Financial Planner)" },
  ]
  return (
    <Layout>
      <Hero image="/imgs/servicios-actividades-ret.jpg" text="Exámenes" />
      <div className="container950">
        <Breadcrumb path={path} />
        <Searcher />
        <div className="row efpa-row">
          <div className="col-12 col-md-3 Topicality-padding-col">
            <CollapseMenu list={menuOptions} title="Menú" />
            <div className="d-none d-md-block">
              <Hours
                list={menuOptions}
                showTitle={false}
                className="LayoutTest-options"
              />
            </div>
          </div>
          <div className="col-12 col-md-9 Topicality-col-9">
            <NewsTabHead
              title="2a Convocatoria 2021 examen EFP (European Financial Planner)"
              subtitle="De 11:30h a 13:30h y de 15h a 19h"
            />
            <NewsTabContent
              reverse
              content={activities1}
              image="/imgs/newstab-image-2.jpeg"
            />
            <div className="ActivityTab-content">
              {parse(activityContent1.content)}
            </div>
            <NewsTabSocial />
          </div>
        </div>
      </div>
    </Layout>
  )
}
